<template>
<div>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <h1 class="title mt-1">Ganancias</h1>
                <p class="desc mt-0 mb-1">Listado de creadores con ganancias acumuladas sin cobrar.</p>
            </v-alert>
        </v-col>

        <!-- Search Bar y Filtros -->
        <v-row class="mx-3">
            <v-col cols="12" md="4" class="px-0">
                <v-text-field v-model="search" @input="fetchUsersEarnings" color="#8061c2" label="Buscar" append-icon="mdi-magnify" single-line hide-details outlined class="mb-2 mx-0 px-0"></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="px-md-2 px-0">
                <v-select
                    v-model="filter"
                    :items="filterOptions"
                    label="Filtrar por"
                    outlined
                    hide-details
                ></v-select>
            </v-col>

            <v-col cols="12" md="4" class="pr-md-2 px-0">
                <v-select v-model="order" :items="orderOptions" label="Ordenar" outlined hide-details></v-select>
            </v-col>
        </v-row>

        <!-- Tabla de datos -->
        <v-data-table :headers="headers" :items="users" :loading="loading" class="elevation-1 mx-3 px-2 py-4" :items-per-page="perPage" :server-items-length="totalUsers" :page.sync="page" :footer-props="{
          itemsPerPageOptions: [50, 100, 200, -1], 
          showFirstLastPage: true
        }" @update:page="fetchUsersEarnings" @update:items-per-page="changePerPage">
            <!-- Columnas y Templates -->

            <template v-slot:item.logo_path="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                            size="40"
                            class="clickable-avatar"
                            @click="showLogo(item.logo_path)"
                            @mouseover="hoveredLogo = item.logo_path"
                            @mouseleave="hoveredLogo = null"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-img :src="item.logo_path" v-if="item.logo_path"></v-img>
                            <v-avatar v-else color="black">
                            <span class="white--text">{{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}</span>
                            </v-avatar>
                        </v-avatar>
                    </template>
                    <span>Ver Imagen</span>
                </v-tooltip>
            </template>

            <template v-slot:item.first_name="{ item }">
                {{ item.first_name }} {{ item.last_name }}
            </template>

            <template v-slot:item.email="{ item }">
                {{ item.email }}
            </template>

            <template v-slot:item.total_earnings="{ item }">
                $ {{ item.total_earnings }} USD
            </template>

            <!-- Botones de acciones -->
            <template v-slot:item.actions="{ item }">
                <!-- Botón para impersonar usuario -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on" @click="impersonateUser(item.user_id)">
                            <v-icon>mdi-account-switch</v-icon>
                        </v-btn>
                    </template>
                    <span>Impersonar Usuario</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <!-- Modal para ampliar el logo -->
        <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
            <v-img :src="selectedLogo" width="14rem"></v-img>
        </v-dialog>

    </v-col>
</div>
</template>

<script>
import axios from '@/axios.js';

export default {
    name: 'UserEarnings',
    data() {
        return {
            users: [],
            search: '',
            loading: true,
            dialog: false,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            totalUsers: 0,
            order: 'desc',
            filter: 'earnings',
            filterOptions: [
                { text: 'Ganancias', value: 'earnings' },
            ],
            orderOptions: [{
                    text: 'Mayor',
                    value: 'desc'
                },
                {
                    text: 'Menor',
                    value: 'asc'
                }
            ],
            headers: [
                {
                    text: 'Avatar',
                    value: 'logo_path',
                    align: 'start'
                },
                {
                    text: 'Creador',
                    value: 'first_name',
                    align: 'start'
                },
                {
                    text: 'Correo',
                    value: 'email',
                    align: 'start'
                },
                {
                    text: 'Ganancias',
                    value: 'total_earnings',
                    align: 'start'
                },
                {
                    text: 'Acciones',
                    value: 'actions',
                    sortable: false
                }
            ]
        };
    },
    watch: {
        order(newVal, oldVal) {
        if (newVal !== oldVal) {
            this.fetchUsersEarnings();
        }
        },
        filter(newVal, oldVal) {
        if (newVal !== oldVal) {
            this.fetchUsersEarnings();
        }
        }
    },

    created() {
        this.fetchUsersEarnings();
    },
    methods: {

        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },

        async fetchUsersEarnings() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-earnings', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        order: this.order
                    }
                });
                this.users = response.data.data;
                this.totalUsers = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.fetchUsersEarnings();
        },
        async impersonateUser(user_id) {
            try {
                const response = await axios.post('/admin/impersonate-user', {
                    user_id
                });
                localStorage.setItem('impersonate_admin', JSON.stringify(localStorage.getItem('user')));
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('token', response.data.token);
                this.$router.push(response.data.user.role_id === 1 ? '/panel/admin' : '/panel');
            } catch (error) {
                console.error('Error impersonating user:', error);
            }
        }
    }
};
</script>

<style scoped>
.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}
</style>
